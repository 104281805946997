import Chart from 'react-google-charts';
import cls from '../widget.module.scss';

export const data = [
  ['', 'Music', 'Dance', 'Humor', 'Slime', 'Gaming'],
  ['Topics', 1000, 1170, 660, 1030, 1030],
];

const options = {
  legend: { position: 'bottom', alignment: 'center' },
  width: 300,
  hAxis: {
    title: '',
  },
  fontSize: 10,
};

const TopicsBar = () => {
  return <Chart chartType="Bar" width="100%" height="200px" data={data} options={options} />;
};

export { TopicsBar };

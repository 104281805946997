import { AgeChart } from '../../../components/widgets/age-chart';
import { AverageEngagementGauge } from '../../../components/widgets/average-engagement-gauge';
import { EngagementGauge } from '../../../components/widgets/engagement-gauge';
import { GenderChart } from '../../../components/widgets/gender-chart';
import { SocialMediaChart } from '../../../components/widgets/social-media-chart';
import { SocialMediaSummary } from '../../../components/widgets/social-media-summary';
import { TopicsBar } from '../../../components/widgets/topics-bar';
import { useAppSelector } from '../../../hooks/store';
import { getUserId } from '../../../store/auth/reducer';

const Influencer = () => {
  const userId = useAppSelector(getUserId);

  return (
    <>
      <section className="dashboard" id="dashboard-section">
        <div className="dashboard__container">
          <div className="dashboard__flex-2-col">
            <div className="row1">
              <div className="dashboard__chart-wrapper">
                <SocialMediaSummary id={userId} />
              </div>

              <div className="dashboard__flex-2-col">
                <div className="dashboard__chart-wrapper row1" id="chart-social-summary">
                  <div style={{ paddingTop: '33px' }}>
                    <SocialMediaChart />
                  </div>
                </div>

                <div className="dashboard__chart-wrapper row2" id="chart-engagement-rate">
                  <AverageEngagementGauge />
                </div>
              </div>
            </div>

            <div className="dashboard__chart-wrapper row3" id="chart-popularity-dynamics">
              <h3 className="dashboard__header">Topics / Score</h3>

              <div className="dashboard__flex-2-col">
                <div className="dashboard__chart-wrapper row1" id="chart-topics">
                  <TopicsBar />
                </div>

                <div className="dashboard__chart-wrapper row1" id="chart-score">
                  <EngagementGauge />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard__flex-2-col">
          <div className="dashboard__container row3">
            <h3 className="dashboard__header">Age / Gender</h3>

            <div className="dashboard__flex-2-col">
              <div className="dashboard__chart-wrapper row1" id="chart-age">
                <AgeChart />
              </div>

              <div className="dashboard__chart-wrapper row1" id="chart-gender">
                <GenderChart />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Influencer;

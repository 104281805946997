import { Chart, GoogleChartOptions } from 'react-google-charts';

import cls from '../widget.module.scss';

const data = [
  ['Label', 'Value'],
  ['UI Score', 54],
];

const options: GoogleChartOptions = {
  backgroundColor: 'transparent',
  height: 200,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  redFrom: 0,
  redTo: 15,
  yellowFrom: 15,
  yellowTo: 35,
  greenFrom: 65,
  greenTo: 100,
  minorTicks: 4,
};

const EngagementGauge = () => {
  return <Chart chartType="Gauge" width="100%" data={data} options={options} />;
};

export { EngagementGauge };

import { Chart, GoogleChartOptions } from 'react-google-charts';
import { useAppSelector } from '../../../hooks/store';
import { selectSocialMediaSummary } from '../../../store/influencer-analytics/reducer';

const chartData: Array<Array<unknown>> = [
  ['', 'percentage', { role: 'style' }, { role: 'annotation' }],
  ['Facebook', 84, '#4688F1', '84%'],
  ['Instagram', 16, '#E7453C', '16%'],
  ['Google', 3, '#F9BB2D', '3%'],
];

const options: GoogleChartOptions = {
  chart: {
    title: undefined,
    subtitle: undefined,
  },
  backgroundColor: 'transparent',
  legend: { position: 'left' },
  width: 300,
  height: 200,
  chartArea: { width: '100%', height: '100%' },
  pieHole: 0.5,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  hAxis: {
    textPosition: 'none',
  },
};

const SocialMediaChart = () => {
  const socialMediaSummary = useAppSelector(selectSocialMediaSummary);

  const testDataSet: Array<Array<unknown>> = [['', 'percentage', { role: 'style' }]];

  if (socialMediaSummary.facebook)
    testDataSet[0].push(['Facebook', socialMediaSummary.facebook.followersCount, '#4688F1']);
  if (socialMediaSummary.instagram)
    testDataSet[0].push(['Instagram', socialMediaSummary.instagram.followersCount, '#E7453C']);
  if (socialMediaSummary.google)
    testDataSet[0].push(['Google', socialMediaSummary.google.subscriberCount, '#F9BB2D']);

  console.log(testDataSet);
  console.log(chartData);

  return <Chart chartType="PieChart" width="100%" data={chartData} options={options} />;
};

export { SocialMediaChart };

import { Chart, GoogleChartOptions } from 'react-google-charts';

import cls from '../widget.module.scss';

const data = [
  ['Label', 'Value'],
  ['Avg.ER%', 23],
];

const options: GoogleChartOptions = {
  backgroundColor: 'transparent',
  height: 200,
  tooltip: {
    isHtml: true,
    trigger: 'hover',
    ignoreBounds: true,
  },
  minorTicks: 4,
};

const AverageEngagementGauge = () => {
  return <Chart chartType="Gauge" width="100%" data={data} options={options} />;
};

export { AverageEngagementGauge };
